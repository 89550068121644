import "../styles/blog.css"

import React, { useEffect, useState } from "react"

import BlogCard from "../components/BlogCard"
import Button from "../components/Button"
import Filter from "../components/Filter"
import { GetBlogEventsPosts } from "../graphql/queries"
import Layout from "../components/layout"
import M from "moment"
import PartnerForm from "../components/FormPartner"
import Seo from "../components/seo"
import TopSection from "../components/TopSection"

const Events = () => {
    const data = GetBlogEventsPosts()
    const { edges } = data.allContentfulEventPost
    const [isPartnerFormVisible, setIsPartnerFormVisible] = useState(false)
    const [location, setLocation] = useState("")
    const [category, setCategory] = useState("upcoming")
    const [showPastEvents, setShowPastEvents] = useState(false)

    const [openedFilter, setOpenedFilter] = useState(null)

    const onOpenFilter = text => {
        if (!openedFilter) {
            setOpenedFilter(text)
        }
    }

    const onCloseFilter = () => {
        if (openedFilter) {
            setOpenedFilter(null)
        }
    }

    const getFilterData = filterBy => {
        const filterArr = [filterBy === "location" ? "all" : "upcoming"]

        let filteredArray = edges.filter(({ node }) => {
            if (node?.eventDateEnd) {
                return M().isBetween(node.eventDate, node.eventDateEnd)
            }
            return M().isBefore(node.eventDate, "hour")
        })

        if (showPastEvents && filterBy === "location") {
            filteredArray = edges.filter(({ node }) => {
                if (node?.eventDateEnd) {
                    return !M().isBetween(node.eventDate, node.eventDateEnd)
                }
                return M().isAfter(node.eventDate, "hour")
            })
        }

        for (const field of filteredArray) {
            const { node } = field
            if (node[filterBy]) {
                if (!filterArr.includes(node[filterBy])) {
                    filterArr.push(node[filterBy])
                }
            }
        }

        return filterArr
    }

    const onSelection = (option, setFunc) => {
        if (["all", "upcoming"].includes(option)) {
            setFunc("")
        } else {
            setFunc(option)
        }
    }

    useEffect(() => {
        setCategory("")
        setLocation("")
    }, [showPastEvents])

    return (
        <Layout withStrokes onBodyClick={() => onCloseFilter()}>
            <Seo title="Events" />
            {/* <TopSection>
                <div className="events--main-image--content">
                    <h1>ARAY activities & events</h1>
                    <p style={{ marginBottom: "1rem" }}>
                        We want women to experience golf in all aspects and use
                        the sport as a tool to connect to new people, learn new
                        things or just have fun!
                    </p>
                    <p style={{ marginBottom: "1rem" }}>
                        We are arranging competitions, meet ups and gatherings
                        where you try new exciting equipment, clothes or take
                        your golf to a new level. Find what suits your interest
                        best!
                    </p>
                    <p>
                        Are you interested in becoming a partner to ARAY Society
                        or hold an event with our community?{" "}
                        <span>Let us know!</span>
                    </p>
                    <div className="events--main--btn-wrapper">
                        <Button onClick={() => setIsPartnerFormVisible(true)}>
                            BECOME A PARTNER
                        </Button>
                    </div>
                </div>
            </TopSection> */}

            <div className="blog--main-wrapper">
                <div className="blog-filters--wrapper">
                    <Filter
                        text="Categories"
                        selected={category || "upcoming"}
                        options={getFilterData("category")}
                        onSelection={opt => onSelection(opt, setCategory)}
                        isOpened={"Categories" === openedFilter}
                        setIsOpened={onOpenFilter}
                        setShowPastEvents={setShowPastEvents}
                        showPastEvents={showPastEvents}
                    />
                    <Filter
                        text="Locations"
                        selected={location || "all"}
                        options={getFilterData("location")}
                        onSelection={opt => onSelection(opt, setLocation)}
                        isOpened={"Locations" === openedFilter}
                        setIsOpened={onOpenFilter}
                    />
                </div>
                <div className="blog-cards--wrapper">
                    {edges
                        .filter(({ node }) => {
                            if (node.eventDate) {
                                if (showPastEvents) {
                                    return node?.eventDateEnd
                                        ? M().isAfter(node.eventDateEnd, "day")
                                        : M().isAfter(node.eventDate, "hour")
                                }

                                return node?.eventDateEnd
                                    ? M().isSameOrBefore(
                                          node.eventDate,
                                          "day"
                                      ) ||
                                          M().isSameOrBefore(
                                              node.eventDateEnd,
                                              "day"
                                          )
                                    : M().isBefore(node.eventDate, "hour")
                            }
                            return node
                        })
                        .sort(
                            (a, b) =>
                                new Date(a.node.eventDate) -
                                new Date(b.node.eventDate)
                        )
                        .filter(({ node }) => {
                            if (location) {
                                return node.location === location
                            }
                            return node
                        })
                        .filter(({ node }) => {
                            if (category) {
                                return node.category === category
                            }
                            return node
                        })
                        .map(({ node }) => (
                            <BlogCard
                                data={node}
                                key={node.id}
                                toPath={`/event/${node.id}`}
                                isEvent
                            />
                        ))}
                </div>
            </div>
            <PartnerForm
                isVisible={isPartnerFormVisible}
                onClose={() => setIsPartnerFormVisible(false)}
            />
        </Layout>
    )
}

export default Events
